import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import Section from 'components/Section'
import styled from 'styled-components'
import Job from './components/job'
import media from 'styles/media'
import SectionTitle from 'components/Section/headerSection'

const BorderedSection = styled.div`
  border-top: 1px solid black;
  margin: 0 0 200px 0;

  ${media.tablet`
    margin: 25px 0 120px 0;
  `}

  ${media.phone`
    margin-bottom: 80px;
  `}
`

const List = () => {
  const [dynamicJobs, setJobs] = useState()
  const [locations, setLocations] = useState()

  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://api.teamtailor.com/v1/jobs?include=department,locations',
    headers: {
      'X-Api-Version': '20240404',
      Authorization: `Bearer ${process.env.GATSBY_TEAMTAILOR_API_KEY}`,
    },
  }

  useEffect(() => {
    axios
      .request(config)
      .then((response) => {
        setJobs(response.data.data)
        setLocations(response.data.included)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }, [])

  return (
    <Section id="current-openings" name="current-openings">
      <SectionTitle
        header
        richText
        headline="Our current openings"
        marginBottom="25px"
      />
      <Container>
        <BorderedSection>
          {dynamicJobs &&
            locations &&
            dynamicJobs.length > 0 &&
            locations.length > 0 &&
            dynamicJobs.map((job, i) => (
              <Job
                key={i}
                location={locations[i] && locations[i].attributes ? locations[i].attributes.city : undefined}
                title={job.attributes.title.replace(/\/in\b/, '')}
                link={job.links['careersite-job-url']}
              />
            ))}
        </BorderedSection>
        {/* <BorderedSection>
        {jobs.map(({ elements: { title, location, slug } }, i) => (
          <Job
            key={i}
            location={location.value}
            title={title.value}
            slug={slug.value}
          />
        ))}
      </BorderedSection> */}
      </Container>
    </Section>
  )
}

List.propTypes = {
  elements: PropTypes.object,
}

export default List
